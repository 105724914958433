import PropTypes from "prop-types"
import React from "react"
import "../styles/header.scss"
import BuffyLogo from "../images/svg/buffy-logo.svg"
import CONSTANTS from "../constants"

const Header = ({ siteTitle }) => (
  <header>
    <div id="main-logo">
      <a href={CONSTANTS.SHOP_URL}>
        <BuffyLogo />
      </a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
