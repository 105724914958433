import ReactPixel from "react-facebook-pixel"

const FacebookPixel = props => {
  const advancedMatching = { em: "some@email.com" } // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: true, // enable logs
  }
  if (ReactPixel && ReactPixel.init) {
    ReactPixel.init("785583144952057", advancedMatching, options)
    ReactPixel.pageView() // For tracking page view
  }
  return null
}

export default FacebookPixel
