// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-js": () => import("./../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bed-builder-slider-bed-js": () => import("./../src/pages/bed-builder-slider/Bed.js" /* webpackChunkName: "component---src-pages-bed-builder-slider-bed-js" */),
  "component---src-pages-bed-builder-slider-footer-js": () => import("./../src/pages/bed-builder-slider/Footer.js" /* webpackChunkName: "component---src-pages-bed-builder-slider-footer-js" */),
  "component---src-pages-bed-builder-slider-index-js": () => import("./../src/pages/bed-builder-slider/index.js" /* webpackChunkName: "component---src-pages-bed-builder-slider-index-js" */),
  "component---src-pages-bed-builder-slider-swatch-js": () => import("./../src/pages/bed-builder-slider/Swatch.js" /* webpackChunkName: "component---src-pages-bed-builder-slider-swatch-js" */),
  "component---src-pages-bed-builder-slider-toggleable-js": () => import("./../src/pages/bed-builder-slider/Toggleable.js" /* webpackChunkName: "component---src-pages-bed-builder-slider-toggleable-js" */),
  "component---src-pages-bedbuilder-js": () => import("./../src/pages/bedbuilder.js" /* webpackChunkName: "component---src-pages-bedbuilder-js" */),
  "component---src-pages-cart-js": () => import("./../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */)
}

