import React, { useState, useEffect } from "react"
import Client from "shopify-buy"

import Context from "../context/StoreContext"

// console.log(".env")
// console.log(JSON.stringify(process.env, null, ""))
// console.log("GATSBY_SHOPIFY_SHOP_NAME", process.env.GATSBY_SHOPIFY_SHOP_NAME)
// console.log(
//   "GATSBY_SHOPIFY_ACCESS_TOKEN",
//   process.env.GATSBY_SHOPIFY_ACCESS_TOKEN
// )

const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
  domain: `${process.env.GATSBY_SHOPIFY_SHOP_NAME}.myshopify.com`,
})

const ContextProvider = ({ children }) => {
  let initialStoreState = {
    client,
    adding: false,
    checkout: { lineItems: [] },
    products: [],
    shop: {},
  }

  const [store, updateStore] = useState(initialStoreState)

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== "undefined"
      const existingCheckoutID = isBrowser
        ? localStorage.getItem("shopify_checkout_id")
        : null

      const setCheckoutInState = checkout => {
        if (isBrowser) {
          localStorage.setItem("shopify_checkout_id", checkout.id)
        }

        updateStore(prevState => {
          return { ...prevState, checkout }
        })
      }

      const createNewCheckout = () => store.client.checkout.create()
      const fetchCheckout = id => store.client.checkout.fetch(id)

      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID)
          // Make sure this cart hasn’t already been purchased.
          if (!checkout.completedAt) {
            setCheckoutInState(checkout)
            return
          }
        } catch (e) {
          localStorage.setItem("shopify_checkout_id", null)
        }
      }

      const newCheckout = await createNewCheckout()
      setCheckoutInState(newCheckout)
    }

    initializeCheckout()
  }, [store.client.checkout])

  return (
    <Context.Provider
      value={{
        store,
        /**
         * add variant(s) to cart
         * @param variantIds single id or an array of ids
         * @param quantity quantity to be applied to _all_ ids
         * @returns {*}
         *
         * Note - this method actually updates the quantities for variants in the cart. In other words, repeated calls
         * to 'add' variant X with quantity 1 will result in a cart with quantity 1, not 1 for each time this method
         * was called
         */
        addVariantToCart: (variantIds = [], quantity, customAttributes) => {
          if (!Array.isArray(variantIds)) variantIds = [variantIds]
          if (variantIds.length === 0 || !quantity) {
            console.error("At least one variant id and quantity are required.")
            return
          }

          updateStore(prevState => {
            return { ...prevState, adding: true }
          })

          const { checkout, client } = store

          const checkoutId = checkout.id
          quantity = parseInt(quantity, 10)
          const lineItemsToUpdate = variantIds.map(variantId => ({
            variantId,
            quantity,
            customAttributes,
          }))

          return client.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then(checkout => {
              updateStore(prevState => {
                return { ...prevState, checkout, adding: false }
              })
            })
        },
        removeLineItem: (client, checkoutID, lineItemID) => {
          return client.checkout
            .removeLineItems(checkoutID, [lineItemID])
            .then(res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res }
              })
            })
        },
        updateLineItem: (
          client,
          checkoutID,
          lineItemID,
          quantity,
          customAttributes
        ) => {
          const lineItemsToUpdate = [
            {
              id: lineItemID,
              quantity: parseInt(quantity, 10),
              customAttributes,
            },
          ]

          return client.checkout
            .updateLineItems(checkoutID, lineItemsToUpdate)
            .then(res => {
              updateStore(prevState => {
                return { ...prevState, checkout: res }
              })
            })
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}
export default ContextProvider
